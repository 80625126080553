import React from 'react'
import ViewResults from './ViewResults';
import Navbar from '../../Navbar';
import styled from 'styled-components';

const ResultPage = () => {

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  `;

  const Content = styled.div`
    display: flex;
    flex: 1;
  `;

  const ContentInner = styled.div`
    width: 100%;
    flex: 1;
  `;


  return (
      <Container>
          <Navbar />
            <Content>
              <ContentInner>
                <ViewResults />
              </ContentInner>

            </Content>
    </Container>
  )
}

export default ResultPage;