import React, { useState, useMemo, useEffect, lazy, Suspense } from 'react';
import {
  Box,
  Text,
  Input,
  Flex,
  useMediaQuery,
  useColorMode,
  Grid,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  SkeletonText,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { useQueryClient } from '@tanstack/react-query';
import useElectionsData from './useElectionsData';
import debounce from 'lodash.debounce';

const VoteLogPage = lazy(() => import('./report'));

const MotionCard = styled(Box)`
  background-color: transparent;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#666' : '#ddd')};
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.theme === 'dark' ? '#555' : '#f5f5f5')};
  }
`;

const PaginationContainer = styled(Box)`
  .pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin: 20px 0;
  }
  .pagination li {
    margin: 0 5px;
  }
  .pagination li a {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid ${(props) => (props.colorMode === 'dark' ? '#666' : '#ddd')};
    color: ${(props) => (props.colorMode === 'dark' ? '#fff' : '#000')};
    text-decoration: none;
    cursor: pointer;
  }
  .pagination .active a {
    background-color: ${(props) => (props.colorMode === 'dark' ? '#3182ce' : '#3182ce')};
    color: white;
  }
  .pagination .disabled a {
    color: #999;
    cursor: not-allowed;
  }
`;

const ScrollableContainer = styled(Box)`
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const SkeletonCard = () => (
  <MotionCard>
    <SkeletonText mt="4" noOfLines={6} spacing="4" />
  </MotionCard>
);

const electionTypeOptions = [
  { value: 'all', label: 'All Types' },
  { value: 'department', label: 'Department Elections' },
  { value: 'faculty', label: 'Faculty Elections' },
  { value: 'hall', label: 'Hall Elections' },
  { value: 'general', label: 'General Elections' },
];

const electionStatusOptions = [
  { value: 'all', label: 'All Elections' },
  { value: 'Ongoing', label: 'Ongoing Elections' },
  { value: 'Upcoming', label: 'Upcoming Elections' },
  { value: 'Completed', label: 'Completed Elections' },
];

const ViewResults = () => {
  const [selectedElectionId, setSelectedElectionId] = useState(null);
  const [showVoteLog, setShowVoteLog] = useState(false);
  const [selectedPositionId, setSelectedPositionId] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterType, setFilterType] = useState('all');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');
  const [completedElections, setCompletedElections] = useState([]);
  const [ongoingElections, setOngoingElections] = useState([]);

  const queryClient = useQueryClient();
  const { data: pastElectionResults, isLoading, error } = useElectionsData(sortOrder);

  const isLargerThan768 = useMediaQuery(['(min-width: 768px)', '(min-width: 1024px)'])[0];
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      console.error("Error fetching election data:", error);
    }

    if (pastElectionResults) {
      setCompletedElections(pastElectionResults.filter((e) => e?.status === 'Completed') ?? []);
      setOngoingElections(pastElectionResults.filter((e) => e?.status !== 'Completed') ?? []);
    }
  }, [pastElectionResults, error]);

  useEffect(() => {
    console.log('Past election results:', pastElectionResults);
  }, [pastElectionResults]);

  const completedElectionResults = useMemo(() => {
    return completedElections.map((election) => ({
      ...election,
      positions: election.positions?.map((position) => ({
        ...position,
        candidates: position.candidates?.map((candidate) => ({
          ...candidate,
          percentage: ((candidate.votes / position.totalVoterCount) * 100).toFixed(2),
        })) ?? [],
      })) ?? [],
    }));
  }, [completedElections]);

  const handleCardClick = (electionId, positionId) => {
    localStorage.setItem('selectedElectionId', electionId);
    localStorage.setItem('selectedPositionId', positionId);
    navigate('/admin-dashboard/view-results/vote-log');
  };

  const getColorScheme = (status) => {
    switch (status) {
      case 'Upcoming':
        return 'blue';
      case 'Ongoing':
        return 'yellow';
      case 'Completed':
        return 'green';
      default:
        return 'gray';
    }
  };

  const debouncedSearch = useMemo(() => debounce(setSearchQuery, 300), []);

  const filteredElections = useMemo(() => {
    return [...completedElectionResults, ...ongoingElections].filter((election) => {
      if (!election) return false;
      const matchesStatus = filterStatus === 'all' || election.status === filterStatus;
      const matchesType = filterType === 'all' || election.type === filterType;
      const matchesSearch = !searchQuery || election.title?.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesStatus && matchesType && matchesSearch;
    });
  }, [completedElectionResults, ongoingElections, filterStatus, filterType, searchQuery]);

  const sortedElections = useMemo(() => {
    return filteredElections.sort((a, b) => {
      if (a.status === 'Completed' && b.status !== 'Completed') return 1;
      if (a.status !== 'Completed' && b.status === 'Completed') return -1;
      return 0;
    });
  }, [filteredElections]);

  const flattenedElections = useMemo(() => {
    return sortedElections.flatMap((election) =>
      election.positions.map((position) => ({ ...election, position }))
    );
  }, [sortedElections]);

  const displayElections = useMemo(() => {
    return flattenedElections.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  }, [flattenedElections, currentPage, itemsPerPage]);

  // Fix: Define the handlePageClick function
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const CompletedElectionCard = React.memo(({ item }) => (
    <MotionCard
      key={`${item.id}_${item.position.positionName}`}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      onClick={() => handleCardClick(item.id, item.position.positionId)}
      theme={colorMode}
    >
      <Box p={4}>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Title: {item.selectedEntity} {item.title}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Type: {item.type}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Position: {item.position.positionName}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Election Date: {item.electionDate}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Election End Date: {item.electionEndDate}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Status: <Badge colorScheme={getColorScheme(item.status)}>{item.status}</Badge>
        </Text>
        <Text fontSize="sm" color="gray.600" mt={2}>
          Winner(s):{' '}
          {item.position.winners.length === 0 ? (
            <Badge colorScheme="yellow" wordBreak="break-word" whiteSpace="normal" display="inline-block" mr={1}>
              undecided (tie)
            </Badge>
          ) : (
            <>
              {item.position.winners.map((winner, index) => (
                <Badge key={index} colorScheme="green" wordBreak="break-word" whiteSpace="normal" display="inline-block" mr={1}>
                  {winner.candidateName}
                </Badge>
              ))}
              {item.position.isUndecided && (
                <Badge colorScheme="yellow" wordBreak="break-word" whiteSpace="normal" display="inline-block" ml={1}>
                  undecided (tie)
                </Badge>
              )}
            </>
          )}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Registered Voters: {item.registeredVoters}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Total Votes: {item.position.totalVoterCount}
        </Text>
        {item.position.candidates && (
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Candidate Name</Th>
                <Th>Votes</Th>
                <Th>Percentage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {item.position.candidates.map((candidate, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{candidate.candidateName}</Td>
                  <Td>{candidate.votes}</Td>
                  <Td>{candidate.percentage}%</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </MotionCard>
  ));

  const OngoingElectionCard = ({ item }) => (
    <MotionCard
      key={`${item.id}_${item.position.positionName}`}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      onClick={() => handleCardClick(item.id, item.position.positionId)}
      theme={colorMode}
    >
      <Box p={4}>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Title: {item.selectedEntity} {item.title}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Type: {item.type}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Position: {item.position.positionName}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Election Date: {item.electionDate}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Election End Date: {item.electionEndDate}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Status: <Badge colorScheme={getColorScheme(item.status)}>{item.status}</Badge>
        </Text>
        <Text fontSize="sm" color="gray.600" mt={2}>
          Winner: <Badge colorScheme="gray">Undecided</Badge>
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Registered Voters: {item.registeredVoters}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={2}>
          Total Votes: {item.position.totalVoterCount}
        </Text>
        {item.position.candidates && (
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Candidate Name</Th>
                <Th>Votes</Th>
                <Th>Percentage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {item.position.candidates.map((candidate, index) => {
                const percentage =
                  item.position.totalVoterCount > 0
                    ? ((candidate.votes / item.position.totalVoterCount) * 100).toFixed(2)
                    : '0.00';
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{candidate.candidateName}</Td>
                    <Td>{candidate.votes}</Td>
                    <Td>{percentage}%</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </Box>
    </MotionCard>
  );

  return (
    <ScrollableContainer p={4} pb={32} bg={colorMode === 'dark' ? '#1a202c' : 'white'}>
      <Flex justifyContent="space-between" alignItems="center" mb={4} direction={isLargerThan768 ? 'row' : 'column'}>
        <Input
          placeholder="Search..."
          size="sm"
          width="150px"
          value={searchQuery}
          onChange={(e) => debouncedSearch(e.target.value)}
        />
        <Select
          value={electionStatusOptions.find((option) => option.value === filterStatus)}
          onChange={(selectedOption) => setFilterStatus(selectedOption.value)}
          options={electionStatusOptions}
        />
        <Select
          value={electionTypeOptions.find((option) => option.value === filterType)}
          onChange={(selectedOption) => setFilterType(selectedOption.value)}
          options={electionTypeOptions}
        />
      </Flex>

      {isLoading ? (
        <Grid templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']} gap={4} justifyItems="center">
          {Array.from({ length: itemsPerPage }).map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </Grid>
      ) : pastElectionResults && pastElectionResults.length > 0 ? (
        <>
          <Grid templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']} gap={4} justifyItems="center">
            {displayElections.slice().reverse().map((item) =>
              item?.status === 'Completed' ? (
                <CompletedElectionCard key={`${item.id}_${item.position?.positionName}`} item={item} />
              ) : (
                <OngoingElectionCard key={`${item.id}_${item.position?.positionName}`} item={item} />
              )
            )}
          </Grid>
          <PaginationContainer colorMode={colorMode}>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(flattenedElections.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </PaginationContainer>
        </>
      ) : (
        <Text>No election data available.</Text>
      )}
      <Outlet />
      {showVoteLog && (
        <Suspense fallback={<Spinner size="xl" />}>
          <VoteLogPage electionId={selectedElectionId} positionId={selectedPositionId} onClose={() => setShowVoteLog(false)} />
        </Suspense>
      )}
    </ScrollableContainer>
  );
};

export default React.memo(ViewResults);
