// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, ...rest }) => {

  const redirect = ['/login', '/hall', '/faculty', '/department'];

  const isAuthenticated = !!localStorage.getItem(rest.auth); // Change 'authToken' to your specific key

  console.log(rest.key);

  return isAuthenticated ? children : <Navigate to={redirect[rest.redirect]} />;
};

export default ProtectedRoute;
