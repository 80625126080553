import styled from 'styled-components';
import Navbar from './Navbar';
import React from 'react';
import StudentDashSideBar from './StudentDashboardComponents/StudentDashboardSideBar';
import { Route, Routes } from 'react-router-dom';
import Nominate from './StudentDashboardComponents/Pages/Nominate';
import StudentVote from './StudentDashboardComponents/Pages/Vote';
import NominationPage from './StudentDashboardComponents/Pages/NominationPage';
import NomineesPage from './StudentDashboardComponents/Pages/NomineesPage';
import VotePage from './StudentDashboardComponents/Pages/VotePage';
import ElectionApplication from './StudentDashboardComponents/Pages/ElectionApplicationPage';
import { ActiveElection } from './StudentDashboardComponents/Pages/ActiveElection';
import StudentsProfile from './StudentDashboardComponents/Pages/StudentsProfile';
import StudentBottomNavbar from './StudentDashboardComponents/StudentBottomNavBar';
import PaymentVerification from './StudentDashboardComponents/Pages/PaymentVerification'; // Import the PaymentVerification component
import ViewResults from './StudentDashboardComponents/Pages/ViewResults';

const StudentDashboard = () => {

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  `;

  const Content = styled.div`
    display: flex;
    flex: 1;
  `;

  const ContentInner = styled.div`
    width: 100%;
    flex: 1;
  `;

  return (
    <Container>
      <Navbar />
      <Content>
      <StudentDashSideBar />
        <Routes>
          <Route path="/" element={<ContentInner><ViewResults /></ContentInner>} />
          <Route path="home" element={<ContentInner><ViewResults /></ContentInner>} />
          <Route path="nominate" element={<Nominate />} />
          <Route path="election" element={<ActiveElection />} />
          <Route path='vote/:electionId' element={<StudentVote />} />
          <Route path='nomination-page/:electionId/:electionData' element={<NominationPage />} />
          <Route path='profile' element={<StudentsProfile />} />
          <Route path='nominees-page/:data/:electionId/:positionId/:electionData' element={<NomineesPage />} />
          <Route path='vote-page/:position/:numOfCandidates/:electionId/:positionId' element={<VotePage />} />
          <Route path='election-application-page/:data/:electionId/:positionId/:positionData/:electionData' element={<ElectionApplication />} />
          <Route path='payment-verification' element={<PaymentVerification />} /> 
        </Routes>
        <StudentBottomNavbar />
      </Content>
    </Container>
  );
}

export default StudentDashboard;
