import { Button } from '@chakra-ui/react'
import { Table, Thead, TabList, Tab, Tabs, Tbody, Tr, Th, Td, TabPanel, TabPanels, HStack, TableContainer } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink,} from '@chakra-ui/react';
import { supabase } from '../../../supabase';
import { getDaysLeft, hasTimestampPassed, isCurrentTimeInRange, isTimestampInFuture } from '../../../util/formatDate';
import { useNavigate } from 'react-router-dom';
import { errorNotice } from '../../../util/toast';
import { ToastContainer } from 'react-toastify';

export const ActiveElection = () => {

  const tableStyle = {width: "100%", height:"60vh", overflowY: "scroll"}

  const jsonString = window.localStorage.getItem('user_auth');
  var parsedData = JSON.parse(jsonString);

  const navigate = useNavigate();

  useEffect(() => {
    fetchDataFromSupabase();
  }, []);

  const fetchDataFromSupabase = async () => {
    try {
      const { data, error } = await supabase.from('elections').select('*');
      if (error) {
        throw error;
      }
      console.log(data);
      setAllElections(data);
    } catch (error) {
      console.error('Error fetching data from Supabase:', error.message);
    }
  };

  const [allElections, setAllElections] = useState([]);

  return (
    <div style={{ display: "inline-flex", alignItems: "center", flexDirection: "column", width: "100%", marginTop: "20px" }}>

      <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 2vh 0px"}}>

        <Breadcrumb>

          <BreadcrumbItem>
            <BreadcrumbLink href='#'>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Election</BreadcrumbLink>
          </BreadcrumbItem>

        </Breadcrumb>

      </HStack>


      <Tabs style={{ width: "90%" }}>
        <TabList>
          <Tab>All</Tab>
        </TabList>

        <TabPanels>

          <TabPanel>
            <TableContainer style={tableStyle}>
              <Table variant='striped' size="lg">
                <Thead>
                  <Tr style={{ textAlign: "start" }}>
                    <Th>All Elections</Th>
                    <Th>Action</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    allElections.map((val) => (

                      ((
                        (val.electionType === "faculty" && val.selectedEntity === parsedData.faculty) ||
                        (val.electionType === "hall" && val.selectedEntity === parsedData.hostel) ||
                        (val.electionType === "department" && val.selectedEntity === parsedData.department) ||
                        (val.electionType === "general")
                      )) ?
                        <Tr>
                          <Td>{val.electionTitle}</Td>
                          <Td>
                            {hasTimestampPassed(val.electionDate)}

                            <Button colorScheme='yellow' onClick={(e) => {
                              e.preventDefault();
                              isCurrentTimeInRange(val.electionDate, val.electionEndDate) ?
                                navigate(`/student-dashboard/vote/${val.id}`)
                                : errorNotice(isTimestampInFuture(val.electionDate) ? "You cannot proceed at the moment till election date": "Election period is over")
                            }} variant='outline'><AiFillEye /></Button>

                          </Td>
                          <Td>{getDaysLeft(val.electionDate)}</Td>
                        </Tr> : <></>

                    ))
                  }
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>


        </TabPanels>

      </Tabs>

      <br></br><br></br>

      <ToastContainer />

    </div>
  )
}
