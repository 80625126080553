import { Button } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FaVoteYea } from 'react-icons/fa';
import { HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { supabase } from '../../../supabase';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { errorNotice } from '../../../util/toast';

const StudentVote = () => {

  const { electionId } = useParams();
  const [loading, setLoadingStatus] = useState(false);

  const jsonString = window.localStorage.getItem('user_auth');
  var parsedData = JSON.parse(jsonString); 

  var navigate = useNavigate();

  useEffect(() => {
    fetchDataFromSupabase();
    fetchVotes();
  }, []);


  function checkIfExists(array, key, value) {
    return array.some(item => item[key] === value);
  }


  const fetchDataFromSupabase = async () => {
    setLoadingStatus(true);
    try {
      const { data,  error } = await supabase.from('election_position')
      .select("*, positions(*)")
      .eq('election_id', electionId);
      if (error) {
        throw error;
      }
      setLoadingStatus(false);
      console.log(data);
      setAllPositions(data);
    } catch (error) {
      setLoadingStatus(false);
      console.error('Error fetching data from Supabase:', error.message);
    }
  };

  const fetchVotes = async () => {
    setLoadingStatus(true);
    try {
      const { data,  error } = await supabase.from('student_election_votes')
      .select("*")
         .eq("student_id", parsedData.id)
         .eq("election_id", electionId);
      if (error) {
        throw error;
      }
      setLoadingStatus(false);
      console.log(data);
      setAllVotes(data);
    } catch (error) {
      setLoadingStatus(false);
      console.error('Error fetching data from Supabase:', error.message);
    }
  };

  const [allPositions, setAllPositions] = useState([]);
  const [allVotes, setAllVotes] = useState([]);

  return <div style={{
    display: "inline-flex",
    alignItems: "center", flexDirection: "column", width: "100%", marginTop: "20px"
  }}>

    <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 2vh 0px"}}>

      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href='#'>Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>Vote</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

    </HStack>

    <br></br><br></br>


    <TableContainer style={{ width: "90%" }}>
      <Table variant='striped' size="lg">
        <TableCaption>Cast your votes</TableCaption>
        <Thead>
          <Tr style={{ textAlign: "start" }}>
            <Th>Role</Th>
            <Th>Action</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            allPositions.map((val) => (
              <Tr>
                <Td>{val.positions.name}</Td>
                <Td>
                    <Button colorScheme='yellow' onClick={()=>{
                        !checkIfExists(allVotes, "position_id", val.positions.id) ? 
                        navigate(`/student-dashboard/vote-page/${val.positions.name}/${val.positions.numberOfCandidates}/${electionId}/${val.positions.id}`)
                        : errorNotice("Vote already casted")
                    }} variant='outline'><FaVoteYea /></Button>
                </Td>
                <Td>{checkIfExists(allVotes, "position_id", val.positions.id) ? "Voted": "Pending"}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>


  </div>;
}


export default StudentVote;