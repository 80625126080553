import styled from 'styled-components';
import Navbar from './Navbar';
import React from 'react';
import { Route, Routes } from 'react-router-dom';


export const HallAdminDashboard = () => {
const Container = styled.div`
 display: flex;
 flex-direction: column;
 height: 100vh;
 width: 100%;
 overflow: hidden;
`;
             
const Content = styled.div`
 display: flex;
 flex: 1;
`;
             
const ContentInner = styled.div`
 width: 100%;
 flex: 1;
`;
             
return (
 <Container>
  <Navbar />
   <Content>
     <Routes>
       <Route path='/' element={<></>} /> 
     </Routes>
    </Content>
    </Container>
 );
}