import { Button, CircularProgress } from '@chakra-ui/react'
import { Table, Thead, TabList, Tab, Tabs, Tbody, Tr, Th, Td, TableCaption, TabPanel, TabPanels, TableContainer } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import { HStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom';
import { supabase } from '../../../supabase';
import { GoPersonAdd } from "react-icons/go";
import { ToastContainer } from 'react-toastify';

const bodyStyle = { display: "inline-flex", alignItems: "center", flexDirection: "column", width: "100%", marginTop: "20px" };

function NomineesPage() {

  const { data, electionId, positionId } = useParams();

  const [loading, setLoadingStatus] = useState(false);

  useEffect(() => {
    fetchDataFromSupabase();
  }, []);


  const fetchDataFromSupabase = async () => {
    setLoadingStatus(true);
    try {
      const { data, error } = await supabase.
        from('nominated_candidates')
        .select(`*, students(name, department, level, hostel)`)
        .eq("election_id", electionId)
        .eq("position_id", positionId)
        .eq("accepted", true);

      if (error) {
        throw error;
      }
      setLoadingStatus(false);
      setAllNominations(data);
    } catch (error) {
      setLoadingStatus(false);
      console.error('Error fetching data from Supabase:', error.message);
    }
  };


  const [nominations, setAllNominations] = useState([]);

  return <div style={bodyStyle}>

    <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 2vh 0px"}}>

      <Breadcrumb>

        <BreadcrumbItem>
          <BreadcrumbLink href='#'>Home</BreadcrumbLink>

        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>Nomination</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>Election</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>{data}</BreadcrumbLink>
        </BreadcrumbItem>

      </Breadcrumb>

    </HStack>


    <Tabs size={{ base: 'sm', md: 'md', lg: 'md', sm: 'sm', xs: 'xs' }}  style={{ width: "90%" }}>

      <TabList>
        <Tab>All</Tab>
        <Tab>Verified</Tab>
        <Tab>Disqualified</Tab>
      </TabList>

      <TabPanels>

        <TabPanel>

          <div style={{
            display: "inline-flex", justifyContent: "flex-end", width: "100%",
            alignItems: "center", margin: "20px"
          }}>
            Nominate Candidate
            <Button colorScheme='yellow'
              style={{ marginLeft: "20px" }}
              onClick={() => console.log("Hello world")} variant='outline'>
              <GoPersonAdd />
            </Button>
          </div>


          {!loading ? <TableContainer style={{ width: "100%" }}>
            <Table variant='striped' size="lg">
              <TableCaption>Applied Candidates</TableCaption>
              <Thead>
                <Tr style={{ textAlign: "start" }}>
                  <Th>Full-name</Th>
                  <Th isNumeric>Department</Th>
                  <Th isNumeric>Level</Th>
                  <Th isNumeric>Hostel</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  nominations.map((value) => (
                    <Tr key={value.id}>
                      <Td>{value.students.name}</Td>
                      <Td>{value.students.department}</Td>
                      <Td>{value.students.level}</Td>
                      <Td>{value.students.hostel}</Td>
                      <Td>{value.verified ? "Pending" : "Verified"}</Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer> : <CircularProgress isIndeterminate />}

        </TabPanel>

      </TabPanels>

    </Tabs>

    <ToastContainer />

  </div>;
}

export default NomineesPage;