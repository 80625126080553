import { Button } from '@chakra-ui/react'
import {Table,Thead,TabList,Tab,Tabs,Tbody,Tr,Th,Td,TabPanel, TabPanels, HStack, TableContainer} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import {AiFillEye} from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { supabase } from '../../../supabase';
import { CircularProgress } from '@chakra-ui/react';
import { errorNotice } from '../../../util/toast';
import { formatDate, hasTimestampElasped, hasTimestampPassed } from '../../../util/formatDate';

const bodyStyle = { display: "inline-flex", alignItems: "center", flexDirection: "column", width: "100%", marginTop: "20px"};
const tableStyle = {width: "100%", height:"60vh", overflowY: "scroll"}

function Nominate() {

    const navigate = useNavigate();

    const [loading, setLoadingStatus] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);

    const field = ['general', 'department', 'faculty', 'hall'];

    const jsonString = window.localStorage.getItem('user_auth');
    var parsedData = JSON.parse(jsonString); 

    useEffect(() => {
      fetchDataFromSupabase(tabIndex);
    }, [tabIndex]);
  
    const fetchDataFromSupabase = async () => {
      setLoadingStatus(true);
      try {
        const { data, error } = await supabase.from('elections')
        .select('*')
        .eq("electionType", field[tabIndex]);
        if (error) {
          throw error;
        }
        setLoadingStatus(false);
        setAllElections(data);
      } catch (error) {
        setLoadingStatus(false);
        console.error('Error fetching data from Supabase:', error.message);
      }
    };

    const [ allElections, setAllElections ] = useState([]);


    return <div style={bodyStyle}>

      <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 3vh 0px"}}>

          <Breadcrumb>

          <BreadcrumbItem>
            <BreadcrumbLink href='#'>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Nomination</BreadcrumbLink>
          </BreadcrumbItem>

          </Breadcrumb>

      </HStack>


    <Tabs  size={{ base: 'sm', md: 'md', lg: 'md', sm: 'sm', xs: 'xs' }} onChange={(index) => setTabIndex(index)} style={{width:"90%"}}>


        <TabList>
            <Tab>General</Tab>
            <Tab>Departmental</Tab>
            <Tab>Faculty</Tab>
            <Tab>Hall</Tab>
        </TabList>


        <TabPanels>

        <TabPanel>
        <TableContainer style={tableStyle}>
          {
            !loading ? <Table variant='striped' size="lg">
            <Thead>
              <Tr style={{textAlign:"start"}}>
                <Th>Elections</Th>
                <Th isNumeric>Start Date</Th>
                <Th isNumeric>End Date</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            
            <Tbody>
                {
                  allElections.map((election)=>(
                    <Tr key={election.id}>
                      <Td>{election.electionTitle}</Td>
                      <Td>{formatDate(election.nominationStartDate)}</Td>
                      <Td>{formatDate(election.nominationEndDate)}</Td>
                      <Td> 
                          <Button colorScheme='yellow'
                          onClick={(e) => {
                            e.preventDefault();
                            hasTimestampPassed(election.nominationStartDate) ?
                            hasTimestampElasped(election.nominationEndDate) ? errorNotice("You cannot proceed, nomination period is over") : 
                            navigate(`/student-dashboard/nomination-page/${election.id}/${encodeURIComponent(JSON.stringify(election))}`)
                              : errorNotice("You cannot proceed at the moment till election date")
                          }}
                          variant='outline'><AiFillEye /></Button>
                      </Td>
                    </Tr>
                  ))
                }
            </Tbody>
            </Table> : <CircularProgress isIndeterminate /> 
          }
        </TableContainer>
        </TabPanel>


        <TabPanel>
        <TableContainer style={tableStyle}>
          {
            !loading ? <Table variant='striped' size="lg">
            <Thead>
              <Tr style={{textAlign:"start"}}>
                <Th>Elections</Th>
                <Th isNumeric>Start Date</Th>
                <Th isNumeric>End Date</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            
            <Tbody>
            {
                  allElections.map((election)=>(

                    ((election.electionType === "department" && election.selectedEntity == parsedData.department)) ?
                    <Tr>
                      <Td>{election.electionTitle}</Td>
                      <Td>{formatDate(election.nominationStartDate)}</Td>
                      <Td>{formatDate(election.nominationEndDate)}</Td>
                      <Td>
                      <Button colorScheme='yellow'
                          onClick={(e) => {
                            e.preventDefault();
                            hasTimestampPassed(election.nominationStartDate) ?
                            hasTimestampElasped(election.nominationEndDate) ? errorNotice("You cannot proceed, nomination period is over") : 
                            navigate(`/student-dashboard/nomination-page/${election.id}/${encodeURIComponent(JSON.stringify(election))}`)
                              : errorNotice("You cannot proceed at the moment till election date")
                          }}
                          variant='outline'><AiFillEye /></Button>
                      </Td>
                    </Tr> : <></>

                  ))
                }
            </Tbody>
            </Table> : <CircularProgress isIndeterminate /> 
          }
        </TableContainer>
        </TabPanel>


        <TabPanel>
        <TableContainer style={tableStyle}>
          {
            !loading ? <Table variant='striped' size="lg">
            <Thead>
              <Tr style={{textAlign:"start"}}>
                <Th>Elections</Th>
                <Th isNumeric>Start Date</Th>
                <Th isNumeric>End Date</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            
            <Tbody>
            {
                  allElections.map((election)=>(

                    ((election.electionType === "faculty" && election.selectedEntity === parsedData.faculty)) ?
                    <Tr>
                      <Td>{election.electionTitle}</Td>
                      <Td>{formatDate(election.nominationStartDate)}</Td>
                      <Td>{formatDate(election.nominationEndDate)}</Td>
                      <Td>
                        <Button colorScheme='yellow'
                            onClick={(e) => {
                              e.preventDefault();
                              hasTimestampPassed(election.nominationStartDate) ?
                              hasTimestampElasped(election.nominationEndDate) ? errorNotice("You cannot proceed, nomination period is over") : 
                              navigate(`/student-dashboard/nomination-page/${election.id}/${encodeURIComponent(JSON.stringify(election))}`)
                                : errorNotice("You cannot proceed at the moment till election date")
                            }}
                            variant='outline'><AiFillEye /></Button>
                      </Td>
                    </Tr> : <></>

                  ))
                }
            </Tbody>
            </Table> : <CircularProgress isIndeterminate /> 
          }
        </TableContainer>
        </TabPanel>


        <TabPanel>
        <TableContainer style={tableStyle}>
          {
            !loading ? <Table variant='striped' size="lg">
            <Thead>
              <Tr style={{textAlign:"start"}}>
                <Th>Elections</Th>
                <Th isNumeric>Start Date</Th>
                <Th isNumeric>End Date</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            
            <Tbody>
            {
                  allElections.map((election)=>(
                    ((election.electionType === "hall" && election.selectedEntity === parsedData.hostel)) ?
                    <Tr>
                      <Td>{election.electionTitle}</Td>
                      <Td>{formatDate(election.nominationStartDate)}</Td>
                      <Td>{formatDate(election.nominationEndDate)}</Td>
                      <Td>
                        <Button colorScheme='yellow'
                            onClick={(e) => {
                              e.preventDefault();
                              hasTimestampPassed(election.nominationStartDate) ?
                              hasTimestampElasped(election.nominationEndDate) ? errorNotice("You cannot proceed, nomination period is over") : 
                              navigate(`/student-dashboard/nomination-page/${election.id}/${encodeURIComponent(JSON.stringify(election))}`)
                                : errorNotice("You cannot proceed at the moment till election date")
                            }}
                            variant='outline'><AiFillEye /></Button>
                      </Td>
                    </Tr> : <></>
                  ))
                }
            </Tbody>
            </Table> : <CircularProgress isIndeterminate /> 
          }
        </TableContainer>
        </TabPanel>

        

        </TabPanels>

    </Tabs>

    </div>;
}

export default Nominate;