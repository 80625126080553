import React, { useState, useEffect, useRef } from 'react';
import {
  CircularProgress, HStack, Text, Input, Stack, Link, Avatar, Box, Icon,
  Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, useBreakpointValue
} from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import { supabase } from '../../../supabase';
import { notify, errorNotice } from '../../../util/toast';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FaCamera } from 'react-icons/fa';
import { parseISO, isValid , isAfter } from 'date-fns';
import styled from 'styled-components';

const defaultAvatar = '/default-avatar.png';

const ScrollableContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const AvatarWithUpload = ({ src, onClick, canUpload }) => (
  <Box position="relative" display="inline-block">
    <Avatar
      src={src}
      alt="Profile Picture"
      size="2xl"
      mb="4"
      onClick={onClick}
      style={{ cursor: canUpload ? 'pointer' : 'not-allowed' }}
      title={canUpload ? "Click to upload a new profile picture" : "Upload not allowed"}
    />
    {canUpload && (
      <Box
        position="absolute"
        bottom="0"
        right="0"
        bg="gray.700"
        color="white"
        borderRadius="full"
        p="2"
      >
        <Icon as={FaCamera} />
      </Box>
    )}
  </Box>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please refresh the page.</h1>;
    }

    return this.props.children; 
  }
}

const StudentsProfile = () => {
  const [loading, setLoadingStatus] = useState(false);
  const [loadingElements, setLoadingElements] = useState([]);
  const [nominations, setAllNominations] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [rrrInputs, setRrrInputs] = useState([]);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [nominationEndDate, setNominationEndDate] = useState(null);
  const [previousNominations, setPreviousNominations] = useState([]);
  const navigate = useNavigate();
  const jsonString = window.localStorage.getItem('user_auth');
  const parsedData = JSON.parse(jsonString);

  const fileInputRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    fetchDataFromSupabase();
    
    const handleOffline = () => {
      errorNotice("You are offline. Please check your internet connection.");
    };
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (checkNominationPeriodOver()) {
        clearInterval(timer);
        setNominationEndDate(prev => new Date(prev));
      }
    }, 60000);
  
    return () => clearInterval(timer);
  }, [nominationEndDate]);

   const fetchDataFromSupabase = async () => {
    setLoadingStatus(true);
    try {
      const [nominationsResponse, prevNominationsResponse, profileResponse] = await Promise.all([
        supabase
          .from('nominated_candidates')
          .select(`
            *,
            positions(*),
            elections(id, electionEndDate, nominationEndDate)
          `)
          .eq("candidate_id", parsedData.id),
        supabase
          .from('nominated_candidates')
          .select(`
            *,
            elections(id, electionEndDate)
          `)
          .eq('candidate_id', parsedData.id),
        supabase
          .from('students')
          .select('profile_picture_url')
          .eq('id', parsedData.id)
          .single()
      ]);
      

      if (nominationsResponse.error) throw new Error(`Nominations error: ${nominationsResponse.error.message}`);
      if (prevNominationsResponse.error) throw new Error(`Previous nominations error: ${prevNominationsResponse.error.message}`);
      if (profileResponse.error && profileResponse.error.code !== 'PGRST116') {
        throw new Error(`Profile error: ${profileResponse.error.message}`);
      }

      setAllNominations(nominationsResponse.data);
      setPreviousNominations(prevNominationsResponse.data);
      setLoadingElements(nominationsResponse.data.map(() => false));
      setPaymentStatus(nominationsResponse.data.map(item => !!item.rrr));
      setRrrInputs(nominationsResponse.data.map(() => ''));
      setPendingStatus(nominationsResponse.data.map(item => !!item.rrr));

      const endDates = nominationsResponse.data.map(nomination => parseISO(nomination.elections.nominationEndDate));
      const validEndDates = endDates.filter(isValid);
      if (validEndDates.length > 0) {
        const latestEndDate = new Date(Math.max(...validEndDates.map(date => date.getTime())));
        setNominationEndDate(latestEndDate);
      } else {
        console.error('No valid nomination end dates found');
      }

      if (profileResponse.data) {
        setProfilePicture(profileResponse.data.profile_picture_url);
      }
    } catch (error) {
      console.error('Error fetching data from Supabase:', error.message);
      errorNotice(`Failed to load profile data: ${error.message}`);
    } finally {
      setLoadingStatus(false);
    }
  };


  const handlePayment = async (index) => {
    if (!rrrInputs[index]) {
      errorNotice("Please enter the RRR.");
      return;
    }

    if (!canAcceptNewNomination()) {
      errorNotice("You cannot accept new nominations at this time.");
      return;
    }

    const newPendingStatus = [...pendingStatus];
    newPendingStatus[index] = true;
    setPendingStatus(newPendingStatus);

    try {
      const { data, error } = await supabase
        .from('nominated_candidates')
        .update({ rrr: rrrInputs[index], accepted: true })
        .eq('candidate_id', parsedData.id)
        .eq('election_id', nominations[index].election_id)
        .select();

      if (error) throw error;
      if (!data || data.length === 0) throw new Error("No rows were updated");

      const newPaymentStatus = [...paymentStatus];
      newPaymentStatus[index] = true;
      setPaymentStatus(newPaymentStatus);
      notify("Successfully uploaded, awaiting review");
    } catch (error) {
      console.error('Error updating RRR in database:', error.message);
      errorNotice(`Failed to update RRR: ${error.message}`);
    } finally {
      const resetPendingStatus = [...pendingStatus];
      resetPendingStatus[index] = false;
      setPendingStatus(resetPendingStatus);
    }
  };

  const declineAcceptance = async (_electionId, index) => {
    setLoadingElements(prevLoadingStates => {
      const newLoadingStates = [...prevLoadingStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });

    try {
      const { error } = await supabase
        .from('nominated_candidates')
        .update({ declined: true })
        .eq('candidate_id', parsedData.id)
        .eq("election_id", _electionId);

      if (error) throw error;

      notify("Nomination was declined");
      fetchDataFromSupabase();

    } catch (err) {
      console.error('Error declining nomination:', err.message);
      errorNotice(`Failed to decline nomination: ${err.message}`);
    } finally {
      setLoadingElements(prevLoadingStates => {
        const newLoadingStates = [...prevLoadingStates];
        newLoadingStates[index] = false;
        return newLoadingStates;
      });
    }
  };

  const handleRrrChange = (index, value) => {
    if (/^[a-zA-Z0-9]{0,12}$/.test(value)) {
      const newRrrInputs = [...rrrInputs];
      newRrrInputs[index] = value;
      setRrrInputs(newRrrInputs);
    } else {
      errorNotice("RRR must be 12 alphanumeric characters");
    }
  };

  const handleProfilePictureUpload = async (event) => {
    setUploading(true);
    const file = event.target.files[0];

    if (!file) {
      setUploading(false);
      errorNotice("No file selected");
      return;
    }

    if (!parsedData || !parsedData.id) {
      errorNotice('User ID is not available');
      setUploading(false);
      return;
    }

    const fileExt = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExt}`;
    const filePath = `public/${fileName}`;

    try {
      const { error: uploadError } = await supabase.storage
        .from('students_display_picture')
        .upload(filePath, file);

      if (uploadError) throw new Error(`Upload error: ${uploadError.message}`);

      const { data: publicURL, error: publicUrlError } = await supabase.storage
        .from('students_display_picture')
        .getPublicUrl(filePath);

      if (publicUrlError) throw new Error(`Public URL error: ${publicUrlError.message}`);
      if (!publicURL || !publicURL.publicUrl) throw new Error('Failed to get public URL');

      const transformedImageUrl = `${publicURL.publicUrl}?width=200&height=200&fit=cover`;

      const { data, error: updateError } = await supabase
        .from('students')
        .update({ profile_picture_url: transformedImageUrl })
        .eq('id', parsedData.id)
        .select();

      if (updateError) throw new Error(`Update error: ${updateError.message}`);
      if (!data || data.length === 0) throw new Error('Profile update did not affect any rows');

      setProfilePicture(transformedImageUrl);
      notify("Profile picture uploaded and processed successfully!");
    } catch (error) {
      console.error('Error in profile picture upload process:', error);
      errorNotice(`Upload failed: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const getStatus = () => {
    if (loading) {
      return 'Loading...';
    }
    if (nominations.length === 0) {
      return 'No Nomination';
    }
    const hasPending = pendingStatus.some(status => status);
    const hasAccepted = nominations.some(nom => nom.accepted);
    const hasDeclined = nominations.some(nom => nom.declined);

    if (hasDeclined) {
      return 'Declined';
    } else if (hasPending) {
      return 'Pending...';
    } else if (hasAccepted) {
      return 'Accepted';
    } else {
      return 'Pending...';
    }
  };

  const canUploadAvatar = nominations.some(nomination => nomination.accepted);

  const handleAvatarClick = () => {
    if (canUploadAvatar) {
      fileInputRef.current.click();
    } else {
      errorNotice("You cannot upload an avatar until you are nominated and accepted.");
    }
  };

  const checkNominationPeriodOver = () => {
    if (!nominationEndDate) return false;
    return new Date() > nominationEndDate;
  };

  const handleLogout = () => {
    window.localStorage.removeItem('user_auth');
    navigate('/login');
  };

  const canAcceptNewNomination = () => {
    console.log("Starting canAcceptNewNomination check");

    const hasWonPreviousElections = previousNominations.some(nomination => nomination.won === true);
    console.log("Has won previous elections:", hasWonPreviousElections);

    if (hasWonPreviousElections) {
      console.log("Cannot accept new nomination due to winning a previous election");
      return false;
    }

    const currentDate = new Date();
    const hasOngoingAcceptedNominations = nominations.some(nomination => {
      if (!nomination.elections || !nomination.elections.electionEndDate) {
        console.log("Warning: Missing election data for nomination", nomination.id);
        return false;
      }
      const electionEndDate = parseISO(nomination.elections.electionEndDate);
      return !isAfter(currentDate, electionEndDate) && nomination.accepted;
    });

    console.log("Has ongoing accepted nominations:", hasOngoingAcceptedNominations);

    const canAccept = !hasOngoingAcceptedNominations;
    console.log("Can accept new nomination:", canAccept);

    return canAccept;
  };

  
  return (
    <ErrorBoundary>
      <ScrollableContainer>
        <HStack spacing='24px' style={{ width: "90%", margin: "20px 0px 30px 0px" }}>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink href='#'>Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href='#'>Profile</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>

        <div style={{ width: "90%" }}>
          <Text fontSize="xl" mb="4"><b>Status: {getStatus()}</b></Text>
          <p><b>{parsedData.name}</b></p><br />

          <AvatarWithUpload
            src={profilePicture || defaultAvatar}
            onClick={handleAvatarClick}
            canUpload={canUploadAvatar}
          />

          <Text fontSize="sm" color="gray.500" mt="2" mb="4">
            Click the avatar to upload a new profile picture
          </Text>

          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleProfilePictureUpload}
            style={{ display: 'none' }}
          />

        <Card>
          <CardBody>
            <p></p><br />
            {
              !loading ? nominations.map((val, index) => (
                <div key={val.id}>
                  <b>{val.positions.name.toString().toUpperCase()}</b>
                  <br />
                  {val.declined ? (
                    <Text>Status: Declined</Text>
                  ) : val.accepted ? (
                    <Text>Status: Accepted</Text>
                  ) : (
                    <>
                      <Text>Amount to be Paid: ₦{val.positions.amount}</Text>
                      {val.amount_paid === val.positions.amount && (
                        <Text>Amount Paid: ₦{val.amount_paid}</Text>)}
                      <br />
                      {!paymentStatus[index] && (
                        <Stack spacing={4}>
                          <Text>Status: {pendingStatus[index] ? "Pending..." : "Awaiting Payment"}</Text>
                          <Link href="https://login.remita.net/remita/onepage/11286991957/service.spa" target="_blank" rel="noopener noreferrer" color="teal.500">
                            Click Here To Make Remita Payment
                          </Link>
                          <Input
                            type="text"
                            placeholder="Enter RRR"
                            value={rrrInputs[index]}
                            onChange={(e) => handleRrrChange(index, e.target.value)}
                          />
                          <HStack spacing='24px'>
                            <Button
                              colorScheme="blue"
                              onClick={() => handlePayment(index)}
                              isLoading={pendingStatus[index]}
                              isDisabled={checkNominationPeriodOver()}
                            >
                              Validate RRR
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => declineAcceptance(val.election_id, index)}
                              isLoading={loadingElements[index]}
                              isDisabled={checkNominationPeriodOver()}
                            >
                              Decline Nomination
                            </Button>
                          </HStack>
                        </Stack>
                      )}
                      <br /><br />
                      {checkNominationPeriodOver() && (
                        <Text color="red.500">Nomination period is over. You cannot accept or decline nominations.</Text>
                      )}
                    </>
                  )}
                </div>
              )) : <CircularProgress style={{ marginBottom: "30px" }} isIndeterminate />
            }
          </CardBody>
        </Card>
          {isMobile && (
            <Button
              colorScheme="red"
              onClick={handleLogout}
              mt={4}
              width="100%"
            >
              Logout
            </Button>
          )}
        </div>
        <ToastContainer />
      </ScrollableContainer>
    </ErrorBoundary>
  );
};

export default StudentsProfile;