import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Typography } from 'antd';
import { supabase } from '../supabase';
import { errorNotice, notify } from '../util/toast';
import { ToastContainer } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from '@chakra-ui/react';


const { Title } = Typography;


const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 90vh; /* Increased height to 90vh */
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  padding-top: 50px;
  width: 60%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;  // Adjusted width for mobile screens
    flex-direction: column;
  }
`;


const Section = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 100%;
`;

const FormSection = styled(Section)`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

const GradientSection = styled(Section)`
  background: linear-gradient(to right, #ce7348, #9a552e);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;


const FormContainer = styled.div`

  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledTitle = styled(Title)`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const GradientTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
`;

const GradientText = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
`;

const HeadOfFacultyLogin = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({ username: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const signIn = async (userMail) => {
    setLoading(true);
    try {

      const {data, error} = await supabase.from("faculty")
      .select("*")
      .ilike("email", userMail)
      .limit(1);

      if(data.length !== 0){
        // console.log(data);
        localStorage.setItem("dean_auth", JSON.stringify(data[0]));
        notify("Login successful");
        navigate('/dean-dashboard');
      }else{
        errorNotice("Uses does not exists");
      }

      setError('');
    } catch (error) {
      setError(error.message);
      errorNotice("An error was encountered when trying to process your request");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormSection>
        <FormContainer>
          <StyledTitle level={4}>Welcome Back</StyledTitle>  

          <GradientTitle>Strictly for Head of Faculty</GradientTitle> <br />   
          
          <Form>

            <Form.Item>

              {!loading ? <GoogleLogin
              onSuccess={credentialResponse => {
                  console.log(credentialResponse);
                  const decoded = jwtDecode(credentialResponse.credential);
                  signIn(decoded.email);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
                // useOneTap
              /> : <CircularProgress isIndeterminate />}

            </Form.Item>

          </Form>

          {error && (
            <div style={{ color: 'red', marginTop: '10px' }}>
              {error}
            </div>
          )}

        <br /><GradientText><Link to='/results'>Click view election results</Link></GradientText>

        </FormContainer>
      </FormSection>

      
      <GradientSection>
        <center>
        <GradientTitle>Moddibbo Adama University</GradientTitle>
        <GradientText>Electronic Voting System</GradientText>
        </center>
      </GradientSection>
      

      <ToastContainer />
    </Container>
  );
};

export default HeadOfFacultyLogin;
