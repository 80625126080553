import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, CircularProgress } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { HStack, Stack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Popover, PopoverContent, PopoverBody, PopoverArrow, PopoverCloseButton } from '@chakra-ui/react';
import { Card, CardBody, Heading, Text, Image } from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, GridItem } from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../../../supabase';
import { errorNotice } from '../../../util/toast';
import { useNavigate } from 'react-router-dom';

const VotePage = () => {
  const history = useNavigate();
  const { position, positionId, electionId, numOfCandidates } = useParams();
  const jsonString = window.localStorage.getItem('user_auth');
  const parsedData = JSON.parse(jsonString);

  useEffect(() => {
    fetchDataFromSupabase();
  }, []);

  const fetchDataFromSupabase = async () => {
    setLoadingCandidate(true);
    try {
      const { data, error } = await supabase
        .from('nominated_candidates')
        .select('*, students(*)')
        .eq("position_id", positionId)
        .eq("election_id", electionId)
        .eq("verified", true);

      if (error) {
        setLoadingCandidate(false);
        throw error;
      }
      if (data.length === 0) {
        setLoadingCandidate(false);
        history(-1);
        errorNotice('No candidates available');
        return;
      } else {
        setLoadingCandidate(false);
        setAllCandidates(data);
      }
    } catch (error) {
      setLoadingCandidate(false);
      console.error('Error fetching data from Supabase:', error.message);
      history(-1);
    }
  };

  const voteCandidate = async () => {
    setVotingStatus(true);
    try {
      const checkVoteStatus = await supabase
        .from("student_election_votes")
        .select("*")
        .eq("student_id", parsedData.id)
        .eq("position_id", positionId)
        .eq("election_id", electionId);

      if (checkVoteStatus.data.length > 0) {
        setVotingStatus(false);
        toast.error('Vote already submitted!');
        history(-1);
        return;
      } else {
        const { error } = await supabase
          .from("student_election_votes")
          .insert(numOfCandidates === 1 ? {
            "student_id": parsedData.id,
            "position_id": positionId,
            "election_id": electionId,
            "candidate_id": candidatesId
          } : {
            "student_id": parsedData.id,
            "position_id": positionId,
            "election_id": electionId,
            "candidate_id": candidatesId,
            "multi_vote": true,
            "other_candidate_ids": multipleCandidatesId
          });

        if (error) {
          setVotingStatus(false);
          throw error;
        }
        setVotingStatus(false);
        toast.success('Vote submitted successfully!');
        history(-1);
      }
    } catch (error) {
      setVotingStatus(false);
      console.error('Error fetching data from Supabase:', error.message);
    }
  };

  const [allCandidates, setAllCandidates] = useState([]);
  const [voting, setVotingStatus] = useState(false);
  const [loadingCandidates, setLoadingCandidate] = useState(false);
  const [candidatesId, setCandidatesId] = useState();
  const [multipleCandidatesId, setMultipleCandidatesId] = useState([]);

  function handleCheckboxChange(event, length) {
    const { value, checked } = event.target;
    if (checked) {
      if (length < numOfCandidates) {
        setMultipleCandidatesId([...multipleCandidatesId, parseInt(value)]);
      } else {
        errorNotice("Maximum number of candidate reached");
        return;
      }
    } else {
      setMultipleCandidatesId(multipleCandidatesId.filter(item => item !== parseInt(value)));
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div style={{ display: "inline-flex", height: "75vh", alignItems: "center", overflowY: "scroll", flexDirection: "column", width: "100%", marginTop: "20px" }}>
      <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 2vh 0px"}}>
        
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href='#'>Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Vote</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>{position}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

      </HStack>

      {!loadingCandidates ? <>
        <p style={{marginTop:"30px"}}>Select desired Candidate</p>
        <div style={{ width: "90%", marginTop: "10px", display: "inline-flex", justifyContent: "center" }}>
          <Popover>
            <center>
              <PopoverContent style={{
                width: "55vw", height: "50vh",
                overflowY: "scroll",
                padding: "20px 0px", alignItems: "center", marginTop: "20px"
              }}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                </PopoverBody>
              </PopoverContent>
            </center>
          </Popover>
        </div>

        <Grid templateColumns='repeat(2, 2fr)' gap={7}>
          {
            allCandidates.map((val, index) =>
              numOfCandidates === 1 ? <GridItem key={index} rowSpan={2} colSpan={1}>
                <input type="radio" onChange={(e) => setCandidatesId(e.target.value)} name="candidate" value={val.students.id} />
                <Card direction={{ base: 'column', sm: 'row' }}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "red",
                    display: "inline-flex",
                    padding: "20px 0px", marginTop: "10px", width: "50vw",
                    justifyContent: "center"
                  }}
                  variant='outline'>
                  <Image
                    src={val.students.profile_picture_url}  // Using `profile_picture_url` for the candidate's image URL
                    alt={val.students.name}
                    boxSize="150px"
                    objectFit="cover"
                  />
                  <Stack>
                    <CardBody>
                      <Heading size='md'>{val.students.name}</Heading>
                      <Text py='2' style={{ textAlign: "start" }}>
                        {val.students.department}
                      </Text>
                    </CardBody>
                  </Stack>
                </Card>
              </GridItem> : <GridItem rowSpan={2} key={index} colSpan={1}>
                <input type="checkbox" key={index} 
                checked={multipleCandidatesId.includes(val.students.id)}
                onChange={(e)=>handleCheckboxChange(e, multipleCandidatesId.length)} value={val.students.id} />
                <Card
                  direction={{ base: 'column', sm: 'row' }}
                  overflow='hidden' style={{
                    marginLeft: "20px",
                    display: "inline-flex",
                    padding: "20px 0px", marginTop: "10px", width: '30vw',
                    justifyContent: "center"
                  }}
                  variant='outline'>
                  <Image
                    src={val.students.profile_picture_url}  // Using `profile_picture_url` for the candidate's image URL
                    alt={val.students.name}
                    boxSize="150px"
                    objectFit="cover"
                  />
                  <Stack>
                    <CardBody>
                      <Heading size='md'>{val.students.name}</Heading>
                      <Text py='2' style={{ textAlign: "start" }}>
                        {val.students.department}
                      </Text>
                    </CardBody>
                  </Stack>
                </Card>
              </GridItem>
            )}
        </Grid>

        {!voting ? 
          numOfCandidates === 1 ? <Button style={{ marginTop: "30px" }} onClick={
            () => {
              if (candidatesId === "") {
                errorNotice("Select candidate");
              } else {
                onOpen();
              }
            }}
            colorScheme={candidatesId === "" ? "gray" : 'green'}>Vote</Button> : 
            <Button style={{ marginTop: "30px", padding:"10px" }} onClick={
              () => {
                if (multipleCandidatesId.length > 0) {
                  if(multipleCandidatesId.length <= numOfCandidates){
                    onOpen();
                  }else{
                    errorNotice(`Maximum number of candidates is ${numOfCandidates}`);
                  }
                } else {
                  errorNotice("Select candidates");
                }
              }}
              colorScheme={
                multipleCandidatesId.length > 0
                ? 'green' : "gray" }>Vote</Button>

            :
            <div style={{ marginTop: "30px" }}>
              <CircularProgress isIndeterminate />
            </div>
        }

      </> : <div style={{ marginTop: "30px" }}>
        <CircularProgress isIndeterminate />
      </div>}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{position}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you have made your decision?
          </ModalBody>

          <ModalFooter>
            {numOfCandidates === 1 ? <Button colorScheme='green' mr={3} onClick={() => {
              if (candidatesId !== "") {
                onClose();
                voteCandidate();
              } else {
                errorNotice("Select candidate");
              }
            }}>
              Yes
            </Button> : <Button colorScheme='green' mr={3} onClick={() => {
              if (multipleCandidatesId.length > 0) {
                onClose();
                voteCandidate();
              } else {
                errorNotice("Select candidates");
              }
            }}>
              Yes
            </Button>}
            <Button colorScheme='red' onClick={onClose} >No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default VotePage;
