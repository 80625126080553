import { Button } from '@chakra-ui/react'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import React, { useState } from 'react';
import { HStack } from '@chakra-ui/react'
import { FormControl, FormLabel } from '@chakra-ui/react';
import { supabase } from '../../../supabase';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import { notify, errorNotice } from '../../../util/toast';
import { useNavigate } from 'react-router-dom';


const ElectionApplication = () => {

   const history = useNavigate();

   const [loading, setLoadingStatus] = useState(false);

   const [nominated, setNominationStatus] = useState(false);

   const [nominatedStudentId, setNominatedStudentId] = useState();

   const [nominatedStudentDepartment, setNominatedStudentDepartment] = useState();

   const { data, electionId, positionId, positionData, electionData } = useParams();

   const myPositionData = JSON.parse(decodeURIComponent(positionData));
   const myElectionData = JSON.parse(decodeURIComponent(electionData));


   const jsonString = window.localStorage.getItem('user_auth');
   var parsedData = JSON.parse(jsonString);

   const validationSchema = Yup.object().shape({
      nominee_id: Yup.string().required('Nominee Id is required'),
   });


   const secondTheNomination = async (id) => {
      setLoadingStatus(true);
      try {

            const { error } = await supabase
            .from('nominated_candidates')
            .update({ seconded: true, seconded_by: parsedData.id })
            .eq('candidate_id', id)
            .eq("election_id", electionId)
            .eq("position_id", positionId);

            if (error) {
               setLoadingStatus(false);
               errorNotice("Error occured when trying to process your request");
            }

            setLoadingStatus(false);
            history(-1);
            notify("Nominated seconded successfully");
            return;
            

      } catch (error) {
         setLoadingStatus(false);
         console.error('Error fetching data from Supabase:', error.message);
      }

   }

   const formik = useFormik({
      initialValues: {
         election_id: electionId,
         nominee_id: "",
         nominated_by: "",
         position_id: positionId,
      },
      validationSchema,
      onSubmit: async (values) => {

         setLoadingStatus(true);
         setNominationStatus(false);

         try {

            if (values.nominee_id === parsedData.matriculationNumber) {
               errorNotice("You cannot nominate yourself");
               setLoadingStatus(false);
               return;
            }

            const student = await supabase
               .from("students")
               .select(`*`)
               .eq("matriculationNumber", values.nominee_id)
               .gte("cgpa", myPositionData.positions.cgpaRequirement);

            /// Replace with nominated candidates

            if (student.data.length > 0) {

               setNominatedStudentId(student.data[0].id);
               setNominatedStudentDepartment(student.data[0].department);

               if (myElectionData.electionType === "department" && myElectionData.selectedEntity !== student.data[0].department) {
                  errorNotice("Candidate is not from the selected department");
                  setLoadingStatus(false);
                  return;
               }

               if (myElectionData.electionType === "faculty" && myElectionData.selectedEntity !== student.data[0].faculty) {
                  errorNotice("Candidate is not from the selected faculty");
                  setLoadingStatus(false);
                  return;
               }

               if (myElectionData.electionType === "hall" && myElectionData.selectedEntity !== student.data[0].hostel) {
                  errorNotice("Candidate is not from the selected hall");
                  setLoadingStatus(false);
                  return;
               }

               const nominated_before = await supabase.from("nominated_candidates") /// Check if the user has nominated for someone before
                  .select('*')
                  .eq('nominator_id', parsedData.id)
                  .eq("position_id", positionId)
                  .eq("election_id", electionId);

               if (nominated_before.data.length === 0) {

                  const nominated_candidate = await supabase
                     .from("nominated_candidates")
                     .select('*')
                     .eq('candidate_id', student.data[0].id)
                     .eq("position_id", positionId)
                     .eq("election_id", electionId)

                  if (nominated_candidate.data.length > 0) {

                     if (nominated_candidate.data[0].seconded === false && nominated_candidate.data[0].nominator_id !== parsedData.id) {
                        errorNotice("Candidate has been nominated, click to button second the nomination");
                        setNominationStatus(true);
                        setLoadingStatus(false);
                     } else {
                        errorNotice("Candidate can no longer be nominated, Slot has reached it's limit");
                        setLoadingStatus(false);
                     }

                  } else {

                     const nominated = await supabase.from("nominated_candidates")
                        .insert([{
                           "candidate_id": student.data[0].id,
                           "position_id": positionId,
                           "election_id": electionId,
                           "nominator_id": parsedData.id
                        }]);

                     if (nominated.error) {
                        setLoadingStatus(false);
                        errorNotice("Error Occurred when nominating candidate");
                        throw new Error(nominated.error.message);
                     }
                     setLoadingStatus(false);
                     notify("Nominated Successfully");
                     history(-1);
                     return;

                  }

               } else {
                  setLoadingStatus(false);
                  errorNotice("You have already nominated a candidate for this position");
               }

            } else {
               errorNotice("This candidate is not eligible");
               setLoadingStatus(false);
               return;
            }

         } catch (error) {
            setLoadingStatus(false);
            console.error('Error fetching data from Supabase:', error.message);
         }
      }
   });

   const {
      handleChange,
      handleBlur,
      handleSubmit,
   } = formik;

   return <div style={{
      display: "inline-flex",
      height: "85vh", overflowY: "scroll",
      alignItems: "center", flexDirection: "column", width: "100%", marginTop: "20px"
   }}>

      <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 2vh 0px"}}>

         <Breadcrumb>

            <BreadcrumbItem>
               <BreadcrumbLink href='#'>Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
               <BreadcrumbLink href='#'>Nomination</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
               <BreadcrumbLink href='#'>Application - {data} </BreadcrumbLink>
            </BreadcrumbItem>

         </Breadcrumb>

      </HStack>


      <div style={{ width: "50%" }}>

         <FormControl>

            <FormLabel>Student ID</FormLabel>
            <Input type='text' name="nominee_id" onChange={handleChange}
               onBlur={handleBlur} /><br /><br />

         </FormControl><br /><br />

         {
            !loading ?
               !nominated ?
                  <Button onClick={(e) => {
                     e.preventDefault();
                     handleSubmit();
                  }} colorScheme='yellow'>Submit</Button>
                  :
                  <Button onClick={(e) => {
                     e.preventDefault();
                     secondTheNomination(nominatedStudentId);
                  }} colorScheme='green'>Second</Button>
               : <CircularProgress isIndeterminate />
         }

         <ToastContainer />

      </div>

   </div>
}

export default ElectionApplication;