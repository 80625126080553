import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import EnhancedLogin from './components/EnhancedLogin';
import StudentDashboard from './components/StudentDashboard';
import { ChakraProvider, ColorModeProvider, Button, useColorMode } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ResultPage from './components/StudentDashboardComponents/Pages/results_page';
import ProtectedRoute from './components/protect_route';

import HeadOfDepartmentLogin from './components/HeadDepartmentLogin';
import HeadOfHallLogin from './components/HeadHallLogin';
import HeadOfFacultyLogin from './components/HeadFacultyLogin';
import { DeansDashboard } from './components/DeanDashboard';
import { HodDashboard } from './components/HODDashboard';
import { HallAdminDashboard } from './components/HallAdminDashboard';

// Component to toggle dark mode
// function DarkModeToggle() {
//   const { colorMode, toggleColorMode } = useColorMode();
//   return (
//     <Button onClick={toggleColorMode} position="fixed" m={4} zIndex={10}>
//       Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
//     </Button>
//   );
// }

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const newAuthID = "74702510078-72tgjht2ccv6lgi50cmv81hiraq7m4ne.apps.googleusercontent.com";
  //const oldAuthID = "105833186527-sm8jpl39vd08jh3rhm11piagn0fkpgcs.apps.googleusercontent.com";

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = localStorage.getItem('user_auth') === 'true';
      setIsLoggedIn(isAuthenticated);
    };

    checkAuthentication();
  
  });


  return (
    <Provider store={store}>
      <ChakraProvider>
        <ColorModeProvider options={{ useSystemColorMode: true }}>
        <GoogleOAuthProvider clientId={newAuthID}>
          <Router>
            <div>

              <Routes>
                {/* Define your routes */}
                <Route path="/" element={<EnhancedLogin />} />
                <Route path="/login" element={<EnhancedLogin />} />
                <Route path='/results' element={<ResultPage />} /> 

                <Route path='/department' element={<HeadOfDepartmentLogin />} /> 
                <Route path='/hall' element={<HeadOfHallLogin />} /> 
                <Route path='/faculty' element={<HeadOfFacultyLogin />} /> 

                <Route path='/student-dashboard/*' element={<ProtectedRoute redirect={0} auth="user_auth">
                    <StudentDashboard />
                </ProtectedRoute>} />

                <Route path='/hall-dashboard/*' element={<ProtectedRoute redirect={1} auth="hall_auth">
                    <HallAdminDashboard />
                </ProtectedRoute>} />

                <Route path='/dean-dashboard/*' element={<ProtectedRoute redirect={2} auth="dean_auth">
                    <DeansDashboard />
                </ProtectedRoute>} />

                <Route path='/hod-dashboard/*' element={<ProtectedRoute redirect={3} auth="hod_auth">
                    <HodDashboard />
                </ProtectedRoute>} />

                {/* <Route path="*">
                  <Redirect to="/login" />
                </Route> */}

                {/* <Route path='' element={<StudentDashboard />} /> */}
                {/* Redirect root to /admin-dashboard */}

                <Route path="/" element={<Navigate to="/login" replace />} />
              </Routes>
            </div>

          </Router>
          <ToastContainer />
          {/* Render DarkModeToggle */}
          {/* {!isLoginPage && <DarkModeToggle />} */}
          </GoogleOAuthProvider>
        </ColorModeProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
