import { useQuery, useQueryClient } from '@tanstack/react-query';
import {supabase} from '../../../supabase';
import { useEffect } from 'react';
import { format, isBefore } from 'date-fns';

// Function to fetch candidates and their votes
const fetchCandidatesAndVotes = async ({ electionId, positionId }) => {
  const [{ data: candidates, error: candidatesError }, { data: votes, error: votesError }] = await Promise.all([
    supabase
      .from('nominated_candidates')
      .select('candidate_id, position_id, verified')
      .eq('election_id', electionId)
      .eq('position_id', positionId),
    supabase
      .from('student_election_votes')
      .select('candidate_id, multi_vote, other_candidate_ids')
      .eq('election_id', electionId)
      .eq('position_id', positionId),
  ]);

  // Handle errors
  if (candidatesError) throw candidatesError;
  if (votesError) throw votesError;

  // Ensure we have data
  const verifiedCandidates = candidates?.filter(candidate => candidate.verified) || [];
  const votesArray = votes || [];

  const voteCounts = verifiedCandidates.reduce((acc, candidate) => {
    acc[candidate.candidate_id] = votesArray.filter(vote => 
      vote.candidate_id === candidate.candidate_id || 
      (vote.multi_vote && vote.other_candidate_ids?.includes(candidate.candidate_id))
    ).length;
    return acc;
  }, {});

  const candidatesData = await Promise.all(verifiedCandidates.map(async candidate => {
    const { data: candidateData, error: candidateError } = await supabase
      .from('students')
      .select('name')
      .eq('id', candidate.candidate_id)
      .single();

    if (candidateError) throw candidateError;

    return {
      candidateId: candidate.candidate_id,
      candidateName: candidateData?.name || 'Unknown',
      votes: voteCounts[candidate.candidate_id] || 0,
    };
  }));

  candidatesData.sort((a, b) => b.votes - a.votes);

  const { data: positionData, error: positionError } = await supabase
    .from('positions')
    .select('numberOfCandidates')
    .eq('id', positionId)
    .single();

  if (positionError) throw positionError;

  const numberOfWinners = positionData?.numberOfCandidates || 1;

  let winners = [];
  let isUndecided = false;

  if (candidatesData.length > 0) {
    winners.push(candidatesData[0]);
    for (let i = 1; i < candidatesData.length; i++) {
      if (candidatesData[i].votes === candidatesData[0].votes) {
        winners.push(candidatesData[i]);
      } else {
        break;
      }
    }

    if (winners.length < numberOfWinners) {
      let nextPosition = winners.length;
      let nextVotes = candidatesData[nextPosition]?.votes || 0;
      let tiedCandidates = 0;

      for (let i = nextPosition; i < candidatesData.length; i++) {
        if (candidatesData[i].votes === nextVotes) {
          tiedCandidates++;
        } else {
          break;
        }
      }

      if (winners.length + tiedCandidates <= numberOfWinners) {
        for (let i = nextPosition; i < nextPosition + tiedCandidates; i++) {
          winners.push(candidatesData[i]);
        }
      } else {
        isUndecided = true;
      }
    }
  }

  let winnerDescription = winners.map(w => w.candidateName).join(', ');
  if (isUndecided) winnerDescription += ', undecided (tie)';

  return {
    candidatesData,
    winners,
    winnerDescription,
    isUndecided,
    numberOfWinners
  };
};

// Function to fetch registered voters
const fetchRegisteredVoters = async () => {
  const { data, error } = await supabase.from('eligible_students').select('entityid, electionType, count');
  if (error) throw error;
  return data;
};

// Function to fetch election data
const fetchElectionData = async ({ queryKey }) => {
  const [_, { sortOrder }] = queryKey;

  const [{ data: electionData, error: electionError }, registeredVotersData] = await Promise.all([
    supabase
      .from('elections')
      .select('*')
      .order('electionDate', { ascending: sortOrder === 'asc' }),
    fetchRegisteredVoters(),
  ]);

  if (electionError) throw electionError;

  const combinedData = await Promise.all(
    electionData.map(async election => {
      const nominationStartDate = format(new Date(election.nominationStartDate), 'MMMM dd, yyyy');
      const nominationEndDate = format(new Date(election.nominationEndDate), 'MMMM dd, yyyy');
      const electionDate = format(new Date(election.electionDate), "MMMM dd, yyyy 'at' HH:mm");
      const electionEndDate = format(new Date(election.electionEndDate), "MMMM dd, yyyy 'at' HH:mm");

      const status = getStatus(new Date(election.electionDate), new Date(election.electionEndDate));

      const { data: electionPositionData, error: electionPositionError } = await supabase
        .from('election_position')
        .select('position_id')
        .eq('election_id', election.id);

      if (electionPositionError) throw electionPositionError;

      const positions = await Promise.all(
        electionPositionData.map(async positionData => {
          const [{ data: positionNameData, error: positionNameError }, { candidatesData, winners, winnerDescription, isUndecided }] = await Promise.all([
            supabase
              .from('positions')
              .select('name, numberOfCandidates')
              .eq('id', positionData.position_id)
              .single(),
            fetchCandidatesAndVotes({ electionId: election.id, positionId: positionData.position_id }),
          ]);

          if (positionNameError) throw positionNameError;

          const totalVoterCount = candidatesData.reduce((total, candidate) => total + candidate.votes, 0);

          return {
            positionId: positionData.position_id,
            positionName: positionNameData.name,
            numberOfCandidates: positionNameData.numberOfCandidates,
            candidates: candidatesData,
            winners,
            winnerDescription,
            isUndecided,
            totalVoterCount,
          };
        })
      );

      const registeredVoters = registeredVotersData.find(item => item.entityid === (election.selectedEntity || 'all') && item.electionType === election.electionType)?.count || 0;

      return {
        id: election.id,
        title: election.electionTitle,
        type: election.electionType,
        positions,
        nominationStartDate,
        nominationEndDate,
        electionDate,
        electionEndDate,
        selectedEntity: election.selectedEntity,
        status,
        registeredVoters,
      };
    })
  );

  return combinedData;
};

// Function to get the status of the election
const getStatus = (startDate, endDate) => {
  const currentDate = new Date();
  return isBefore(currentDate, startDate) ? 'Upcoming' : isBefore(currentDate, endDate) ? 'Ongoing' : 'Completed';
};

// Function to fetch votes for the candidates
const fetchVotes = async ({ electionId, positionId, candidates }) => {
  const { data: votes, error: votesError } = await supabase
    .from('student_election_votes')
    .select('candidate_id, multi_vote, other_candidate_ids')
    .eq('election_id', electionId)
    .eq('position_id', positionId);

  if (votesError) throw votesError;

  const voteCounts = candidates.reduce((acc, candidate) => {
    acc[candidate.candidateId] = votes.filter(vote =>
      vote.candidate_id === candidate.candidateId ||
      (vote.multi_vote && vote.other_candidate_ids?.includes(candidate.candidateId))
    ).length;
    return acc;
  }, {});

  return voteCounts;
};

// Custom hook to fetch elections data
const useElectionsData = (sortOrder) => {
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ['elections', { sortOrder }],
    queryFn: fetchElectionData,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Polling mechanism for updating votes every 5 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      if (data) {
        await Promise.all(
          data.map(async (election) => {
            if (election.status === 'Completed') {
              return; // Skip completed elections
            }
            const updatedPositions = await Promise.all(
              election.positions.map(async (position) => {
                const newVoteCounts = await fetchVotes({
                  electionId: election.id,
                  positionId: position.positionId,
                  candidates: position.candidates,
                });
                const updatedCandidates = position.candidates.map(candidate => ({
                  ...candidate,
                  votes: newVoteCounts[candidate.candidateId] || 0,
                }));
                return {
                  ...position,
                  candidates: updatedCandidates,
                  totalVoterCount: updatedCandidates.reduce((total, candidate) => total + candidate.votes, 0),
                };
              })
            );
            queryClient.setQueryData(['elections', { sortOrder }], (oldData) => oldData.map(e => e.id === election.id ? { ...e, positions: updatedPositions } : e));
          })
        );
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [data, queryClient, sortOrder]);

  return { data, error, isLoading };
};

export default useElectionsData;
