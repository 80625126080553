import {Button } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import { HStack } from '@chakra-ui/react'
import { AiFillEye } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import { supabase } from '../../../supabase';
import { CircularProgress } from '@chakra-ui/react';

function NominationPage() {

  const { electionId, electionData } = useParams();
  const [loading, setLoadingStatus] = useState(false);

  const jsonString = window.localStorage.getItem('user_auth');
  var parsedData = JSON.parse(jsonString); 

  const myElectionData = JSON.parse(decodeURIComponent(electionData));

  const tableStyle = {width: "90%", height:"65vh", overflowY: "scroll"}

  useEffect(() => {
    fetchDataFromSupabase();
  }, []);

  const fetchDataFromSupabase = async () => {
    setLoadingStatus(true);
    try {
      const { data,  error } = await supabase.from('election_position')
      .select("*, positions(*)")
      .eq('election_id', electionId)
      if (error) {
        throw error;
      }
      setLoadingStatus(false);
      setAllPositions(data);
    } catch (error) {
      setLoadingStatus(false);
      console.error('Error fetching data from Supabase:', error.message);
    }
  };

  const [ allPositions, setAllPositions ] = useState([]);

  function checkIfExists(array, key, value) {
    return array.some(item => item[key] === value);
  }


  return <div style={{
    display: "inline-flex",
    alignItems: "center", flexDirection: "column", width: "100%", marginTop: "20px"
  }}>

    <HStack spacing='24px' style={{width:"90%", margin:"2vh 0px 2vh 0px"}}>

      <Breadcrumb>

      <BreadcrumbItem>
        <BreadcrumbLink href='#'>Home</BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink href='#'>Nomination</BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <BreadcrumbLink href='#'>Election</BreadcrumbLink>
      </BreadcrumbItem>

      </Breadcrumb>

    </HStack>


    <TableContainer style={tableStyle}>
      { !loading ? <Table variant='striped' size="lg">
        <TableCaption>Available Positions</TableCaption>
        <Thead>
          <Tr style={{ textAlign: "start" }}>
            <Th>Aspiring Position</Th>
            <Th>View</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            allPositions.map((position)=>(
              <Tr  key={position.id}>
                <Td>{position.positions.name}</Td>
                <Td><Link to={`/student-dashboard/nominees-page/${position.positions.name}/${position.election_id}/${position.position_id}/${encodeURIComponent(JSON.stringify(myElectionData))}`} ><Button colorScheme='yellow'  variant='outline'><AiFillEye /></Button></Link></Td>
                <Td>
                    <Link 
                    to={`/student-dashboard/election-application-page/${position.positions.name}/${electionId}/${position.position_id}/${encodeURIComponent(JSON.stringify(position))}/${encodeURIComponent(JSON.stringify(myElectionData))}`} >
                    {<Button colorScheme='yellow'>Nominate</Button>}
                   </Link>
                 
                </Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table> : <CircularProgress isIndeterminate /> }
    </TableContainer>

  </div>;
}

export default NominationPage;