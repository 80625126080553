import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentVerification = () => {
  const location = useLocation();

  const verifyPayment = async (reference, hash) => {
    const amount = localStorage.getItem('expectedAmount');
    const encoder = new TextEncoder();
    const data = encoder.encode(`${amount}${reference}`);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const expectedHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    if (expectedHash === hash) {
      alert("Payment verified successfully!");
      // Further process to update the status or whatever necessary
      // Example: Update status in Supabase
    } else {
      alert("Payment verification failed!");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reference = searchParams.get('reference');
    const hash = searchParams.get('hash');

    if (reference && hash) {
      verifyPayment(reference, hash);
    } else {
      alert("Invalid payment details!");
    }
  }, [location]);

  return (
    <div>
      <h1>Verifying your payment...</h1>
    </div>
  );
};

export default PaymentVerification;
