import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiHome, FiSettings, FiLogOut } from 'react-icons/fi';
import { FiUser } from "react-icons/fi";
import {BsPersonDown} from 'react-icons/bs';
import {MdOutlineHowToVote} from 'react-icons/md';
import { useColorMode } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, CircularProgress, RadioGroup } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

const SidebarContainer = styled.nav`
  background: ${props => (props.theme === 'dark' ? '#333' : 'linear-gradient(to right, #ce7348, #9a552e)')};
  padding: 0px;
  width: 100%;
  display: none;
  bottom: 0;
  position: fixed;
  color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  flex-direction: row;
  @media (max-width: 768px){
   display: flex;
  }
  @media (max-width: 490px) {
   font-size: 1.5rem;
   padding: 15px 0px;
   width: 100%
 }
`;

const LinkList = styled.ul`
  list-style-type: none;
  width: 100vw;
  padding: 0;
  display: inline-flex;
  justify-content: space-evenly;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.3s ease;
  cursor: pointer;
  font-size: 0.6rem;

  &:hover {
    color: #fce364;
  }

  width: 80px;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }

  @media (max-width: 480px) {
    font-size: 0.5rem;
  }
`;

const LinkItem = styled.li`
  display: inline-flex;
  justify-content: center;
  width: 100%
`;

const Icon = styled.div`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 5px;

  @media (max-width: 530px) {
     font-size: 1.4rem;
  }

  ${StyledLink}:hover & {
    color: #fce364;  // Change icon color when the link is hovered
  }
`;


function StudentBottomNavbar() {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <SidebarContainer theme={colorMode}>
      <LinkList>
        <LinkItem>
            <StyledLink to="/student-dashboard/home" theme={colorMode}>
              <Icon><FiHome /></Icon>
              Home
            </StyledLink>
          </LinkItem>
        <LinkItem>
          <StyledLink to="/student-dashboard/nominate" theme={colorMode}>
            <Icon><BsPersonDown /></Icon>
            Nominate
          </StyledLink>
        </LinkItem>
        <LinkItem>
          <StyledLink to="/student-dashboard/election" theme={colorMode}>
            <Icon><MdOutlineHowToVote /></Icon>
            Vote
          </StyledLink>
        </LinkItem>
        <LinkItem>
          <StyledLink to="/student-dashboard/profile" theme={colorMode}>
            <Icon><FiUser /></Icon>
            Profile
          </StyledLink>
        </LinkItem>
        {/* <LinkItem>
          <StyledLink onClick={()=>onOpen()} theme={colorMode}>
            <Icon><FiLogOut /></Icon>
            Logout
          </StyledLink>
        </LinkItem> */}
      </LinkList>


    </SidebarContainer>
  );
}

export default StudentBottomNavbar;
